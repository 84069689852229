import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import img1 from '../assest/images/pexels-athena-3588036.jpg'
import img2 from '../assest/images/pexels-engin-akyurt-2036686.jpg'
import img3 from '../assest/images/ourbusiness.jpg'
import img4 from '../assest/images/pexels-sevenstorm-juhaszimrus-439416.jpg'
import ServicesDivisionData from "../data/ServicesDivisionData";
import { Link } from "react-router-dom";
import MaintenanceServicesData from "../data/MaintenanceServicesData";
import majorIndustrialProjects from '../assest/images/majorIndustrialProjects.jpg'
import SingleIndustrialProjects from '../assest/images/SingleIndustrialProjects.jpg'
import OffshoreServicesDivision from '../assest/images/OffshoreServicesDivision.jpg'


function AboutUs() {
  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };
  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const settings = {
    infinite: true,
    lazyload: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const image = [
    {
      img: img1,
      textinfo: 'The existing Industrial Interchange in Zone 57 Salwa Road – The works consist of D&B Modifications with Steel Bridge at grade to change the Roundabout to Signal I/C. Protection/ Modification of utilities, New ITS & FF networks, Landscaping, Safety barriers, Street Lighting, Road signs and markings. The new Mubaireek Interchange in Zone 55- works include D&B of two Decks multi-span bridges, Ramps and Loops, new Storm water & TSE networks, Landscaping, Street lighting, Road signs and markings.',
    },
    {
      img: img2,
      textinfo: 'The scope of work consisted of MEP works for the Msheireb station which is situated at the heart of the Doha Metro Network, where the red, green and gold lines intersect. The station lies between c. 15 m and 40 m below ground.',
    },
    {
      img: img3,
      textinfo: 'The existing Industrial Interchange in Zone 57 Salwa Road – The works consist of D&B Modifications with Steel Bridge at grade to change the Roundabout to Signal I/C. Protection/ Modification of utilities, New ITS & FF networks, Landscaping, Safety barriers, Street Lighting, Road signs and markings. The new Mubaireek Interchange in Zone 55- works include D&B of two Decks multi-span bridges, Ramps and Loops, new Storm water & TSE networks, Landscaping, Street lighting, Road signs and markings.',
    },
    {
      img: img4,
      textinfo: 'The scope of work consisted of MEP works for the Msheireb station which is situated at the heart of the Doha Metro Network, where the red, green and gold lines intersect. The station lies between c. 15 m and 40 m below ground.',

    },
  ];
  return (
    <div>
      <section className="about">
        <div className="bg_image_1">
          <div className="content">
            <h3>About Us</h3>
          </div>
        </div>
      </section>
      <section className="about-us">
        <div className="container py-5">
          <div className="inner">
            <p className=" fw-bold">
              Midtech Engineering and Construction Company W.L.L.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="row row-cols-1 row-cols-md-1 row-cols-lg-2">
            <div className="col py-2">
              <div className="card card-color shadow" style={{ "height": "22rem" }}>
                <div className="card-body ">
                  <h1 className="card-title" style={{ textAlign: "center" }}>
                    <i className="bx bx-bullseye"></i>
                  </h1>
                  <h2
                    className="card-subtitle mb-2 "
                    style={{ textAlign: "center" }}
                  >
                    Introduction
                  </h2>
                  <p className="card-text">
                    Midtech Engineering and Construction Company W.L.L. is the leading National EPC & Maintenance Contractor in the Oil & Gas, Petrochemical, Fertilizer and Power industries.
                  </p>
                  <p className="card-text">
                    Formally known as MECON, the company was established in 1975. Since then, Midtech has contributed immensely to the hydrocarbon infrastructural development in Qatar.
                  </p>
                  <p className="card-text">
                    Midtech employs over 5,000 skilled personnel on permanent basis that are supplemented by short-term personnel as required to meet project needs.
                  </p>


                </div>
              </div>
            </div>
            <div className="col py-2">
              <div className="card card-color shadow" style={{ "height": "22rem" }}>
                <div className="card-body">
                  <h1 className="card-title " style={{ textAlign: "center" }}>
                    <i className="bx bx-target-lock"></i>
                  </h1>
                  <h2
                    className="card-subtitle mb-2 "
                    style={{ textAlign: "center" }}
                  >
                    What We Do
                  </h2>
                  <p className="card-text">
                    Midtech is a multi-discipline industrial Engineering & Construction Company, capable of delivering total solutions to its clients in the Oil & Gas industries.
                  </p>
                  <p className="card-text">
                    To focus on the specific needs of its clients, Midtech operates through its three divisions:
                  </p>
                  <ul>
                    <li>Industrial Projects.</li>
                    <li>Maintenance Services.</li>
                    <li>Mechanical Fabrication.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className=" m-5 px-3">
        <h3 className=" fw-bold py-2">Midtech’ s capabilities at a glance</h3>
        <ul>
          <li>Major National EPIC & Maintenance Contractor</li>
          <li>“Grade A” Classified Civil Contractor</li>
          <li>“Grade 1” KAHRAMAA Licensed Electrical Contractor</li>
          <li>In-house Manpower Resources more than 5,000 Nos.</li>
          <li>In-house Construction Equipment Resources</li>
          <li>Licensed Medical Facility with qualified Doctor & staff</li>
          <li>Accommodation Camps at Industrial Hubs</li>
          <li>Own Marine Yard/ Wharf Side Facility</li>
          <li>Fabrication & Maintenance Services Facilities, West Side, RLIC and Offshore (Mesaieed) Fabrication Yards</li>
        </ul>
      </div>


      <section>
        <hr />
        <div className="container py-5">
          <h1 className="">
            Industrial Projects
          </h1>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 ">
            <div className="col py-4 aboutDivisiondiv">
              <div
                className="card card-color mx-auto shadow p-2"
              >
                <img
                  src={majorIndustrialProjects}
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">Major Multi- Discipline Projects</h5>
                  <ul>
                    <li>Main EPC Contractor</li>
                    <li>General Construction Contractor</li>
                  </ul>
                </div>

              </div>
            </div>
            <div className="col py-4 aboutDivisiondiv">
              <div
                className="card card-color mx-auto shadow p-2"
              >
                <img
                  src={SingleIndustrialProjects}
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title"> Single Discipline Projects</h5>
                  <p className="card-text ">
                    large projects in the following discipline :
                  </p>
                  <ul>
                    <li>Mechanical, Electrical, Instrumentation and Civil</li>
                  </ul>
                </div>
              </div>
            </div>


          </div>
        </div>
      </section>

      <section>
        <hr />
        <div className="container py-5">
          <h1 className="">
            Maintenance Services
          </h1>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 ">
            {MaintenanceServicesData.map((item) => {
              return (
                <div className="col py-4 aboutDivisiondiv">
                  <div
                    className="card card-color mx-auto shadow p-2"
                  >
                    <img
                      src={item.src}
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <h5 className="card-title">{item.heading}</h5>
                      <p className="card-text ">
                        {item.aboutInfo}
                      </p>
                    </div>
                  </div>
                </div>
              )
            })}



          </div>
        </div>
      </section>

      <section>
        <hr />
        <div className="container py-5">
          <h1 className="">
            Mechanical Fabrication
          </h1>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 ">
            <div className="col py-4 aboutDivisiondiv">
              <div
                className="card card-color mx-auto shadow p-2"
              >
                <img
                  src={OffshoreServicesDivision}
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">Marine and Heavy Structural Fabrication Yard</h5>
                  <p className="card-text ">
                    Off shore topside jacket fabrication, Fabrication of platform structures, Large bore pipe spool fabrication, Blasting and painting work.
                  </p>
                </div>


              </div>
            </div>
            <div className="col py-4 aboutDivisiondiv">
              <div
                className="card card-color mx-auto shadow p-2"
              >
                <img
                  src={majorIndustrialProjects}
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title"> Light Onshore Structural Fabrication Shop</h5>
                  <p className="card-text ">
                    Pipe and structural prefabrication, pressure vessels and process skids in our ASME certified shop.
                  </p>
                </div>
              </div>
            </div>


          </div>
        </div>
      </section>


      {/* <div className="business">
        <h1
          style={{
            textAlign: "center",
            paddingBottom: "50px",
            paddingTop: "50px",
          }}
        >
          OUR HISTORY
        </h1>
        <div className="container-fluid pb-5">
          <Slider {...settings}>
            {image.map((img) => (
              <div className="container ">
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 gap-4 ">
                  <div className="col business-card1">
                    <img
                      src={img.img}
                      className="card-img-top rounded-2"
                      alt="..."
                    />
                  </div>
                  <div className="col">
                    <div className="card business-card shadow">
                      <div className="card-body">
                        <h5 className="card-title">2000-2005</h5>
                        <p className="card-text">
                          {img.textinfo}
                        </p>
                        <a href="/" className="btn btn-primary">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div> */}
    </div>
  );
}

export default AboutUs;

import React from "react";

function Career() {
  return (
    <div>
      <section className="career">
        <div className="bg_image_1">
          <div className="content">
            <h3>Career</h3>
          </div>
        </div>
        <div className="container py-5">
          <h3 style={{textAlign:"center"}}>
            Thank you for showing your interest in joining Midtech Engineering and Construction Company W.L.L.
            We don’t have any open positions right now.
          </h3>
          <p className="py-5" style={{textAlign:"center"}}>Please keep checking this space for any new openings.</p>
        </div>
      </section>
    </div>
  );
}

export default Career;

import React from "react";
import { Link } from "react-router-dom";
import { FaSquareFacebook, FaSquareTwitter, FaLinkedin } from "react-icons/fa6";


function Footer() {
  return (
    <div>
      <footer className="text-center text-lg-start footer text-dark">
        <section className="d-flex justify-content-between copyright py-3">
          <div className="px-4">
            <span>Get connected with us </span>
          </div>
        </section>
        <section className="footer">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-12 col-lg-12 col-xl-4 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold">
                  Midtech Engineering and Construction Company W.L.L.
                </h6>
                <img
                  src="/images/logo7.png"
                  alt=""
                  style={{
                    height: "150px",
                    // marginLeft: "20%",
                    // paddingTop: "10px",
                  }}
                />
                <p style={{ textAlign: "justify" }}>
                  Midtech Engineering and Construction Company W.L.L.
                  is the leading National EPC and Maintenance
                  Contractor in the Oil & Gas,Petrochemical, Fertilizer, and Power industries.
                </p>

                <ul className="navbar-nav d-flex">
                  <li className="nav-item">
                    <Link
                      className="nav-link animated fadeInRight"
                      to="/"
                      style={{
                        animationDelay: ".9s",
                        animateDuration: "800ms",
                        fontSize: "30px",
                      }}
                    >
                      <FaSquareFacebook className="" />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link animated fadeInRight"
                      to="/"
                      style={{
                        animationDelay: ".9s",
                        animateDuration: "800ms",
                        fontSize: "30px",
                      }}
                    >
                      <FaSquareTwitter className="" />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link animated fadeInRight"
                      to="/"
                      style={{
                        animationDelay: ".9s",
                        animateDuration: "800ms",
                        fontSize: "30px",
                      }}
                    >
                      <FaLinkedin className="" />
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <div className="ptag">
                  <h6 className="fw-bold">Address</h6>930 Qatari Bin Al Fujaah Street,
                  <br />Fereej Bin Mahmoud, <br />
                  Doha, Qatar
                </div>
                <div className="ptag">
                  <h6 className="fw-bold">Mail</h6>
                  info@midtechecc.com
                </div>
                <div className="ptag">
                  <h6 className="fw-bold">Call</h6>
                  +974 4460 4830
                </div>
                <div className="ptag">
                  <h6 className="fw-bold">Timing</h6>
                  Mon-Sat: 10am – 7pm
                </div>
              </div>
              <div className="col-md-4 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold">LINKS</h6>
                <p>
                  <Link to="/" >
                    Home
                  </Link>
                </p>
                <p>
                  <Link to="/ourpeople" >
                    Team
                  </Link>
                </p>
                <p>
                  <Link to="/blogs">
                    Blogs
                  </Link>
                </p>
                <p>
                  <Link to="/imagegallery">
                    Image Gallery
                  </Link>
                </p>
                <p>
                  <Link to="/videogallery">
                    Video Gallery
                  </Link>
                </p>
                <p>
                  <Link to="/contact">
                    Contact Us
                  </Link>
                </p>
                <p>
                  <Link to="/career">
                    Career
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="text-center copyright p-3">
          Copyright ©2024 Midtech Engineering and Construction Company W.L.L. All Rights Reserved <br />
          <strong>Powered By</strong>{" "}
          <a
            style={{ textDecoration: "none", color: 'white' }}
            href="https://abarissoftech.com/"
          >
            Abaris Softech
          </a>
        </div>
      </footer>

      {/* <footer>
        <section className="footer py-5">
          <div className="container px-5 ">
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
              <div className="col">
                <h3>Location</h3>
                <p>Office No. 703,DLF Tower A,Jasola District Center,New Delhi-110044, INDIA
                </p>
              </div>
              <div className="col"> <h3>Call Us</h3> <p>+91 9123456789</p></div>
              <div className="col"><h3>Mail Us</h3> <p>info@example.com</p></div>
              <div className="col "><h3>Follow Us</h3><i className='bx bxl-instagram-alt'></i><i className='bx bxl-facebook-circle'></i><i className='bx bxl-linkedin-square'></i><i className='bx bxl-twitter' ></i></div>
            </div>
          </div>
        
        <div class="container">
  <footer class="py-3 my-4">
    <ul class="nav justify-content-center border-top pb-3 mb-3">
      <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">Home</a></li>
      <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">Features</a></li>
      <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">Pricing</a></li>
      <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">FAQs</a></li>
      <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">About</a></li>
    </ul>
  </footer>
</div>
</section>
        <div
            className="container-fluid copyright py-3"
          >
            <div className="text-center">
            Copyright ©2024 AAHI Group  All Rights Reserved <br />
            <strong>Powered By: </strong>
            <a   style={{textDecoration:'none',color:'white'}} href="https://abarissoftech.com/">Abaris Softech</a>
            </div>
          </div>
      </footer> */}
    </div>
  );
}

export default Footer;
